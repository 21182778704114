export default {
  props: {
    node: {
      type: Object,
    },
    isChild: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    type() {
      return this.node.type
    },
  },
}